// extracted by mini-css-extract-plugin
export var button = "services-module--button--0f6c6";
export var descriptionTitle = "services-module--descriptionTitle--7cce9";
export var descriptionTitleMobile = "services-module--descriptionTitleMobile--06279";
export var firstSectionContainer = "services-module--firstSectionContainer--db683";
export var firstSectionDescriptionContainer = "services-module--firstSectionDescriptionContainer--6e45d";
export var grid = "services-module--grid--322c1";
export var heading = "services-module--heading--5d9a1";
export var heroImage = "services-module--heroImage--9620f";
export var hqImage = "services-module--hqImage--90109";
export var imageMobile = "services-module--imageMobile--98ec7";
export var left = "services-module--left--8c7aa";
export var leftBody = "services-module--leftBody--ad71b";
export var markdown = "services-module--markdown--be4a7";
export var mobileTitle = "services-module--mobileTitle--5cbe9";
export var reverseOrderOnMobile = "services-module--reverseOrderOnMobile--641d2";
export var right = "services-module--right--a1bda";
export var rightS1 = "services-module--rightS1--137ee";
export var rowSpan2 = "services-module--rowSpan2--91351";
export var sectionContainer = "services-module--sectionContainer--9b75f";
export var sectionDescriptionContainer = "services-module--sectionDescriptionContainer--319dc";
export var servicesDesctiption = "services-module--servicesDesctiption--ed15f";
export var teamInformationText = "services-module--teamInformationText--67940";
export var title = "services-module--title--9d1c0";