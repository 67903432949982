import * as React from "react";
import { graphql } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import classNames from "classnames";

import Layout from "../components/layout";
import Button from "../components/ui/button";
import Title from "../components/ui/title";
import Section from "../components/layout/section";
import TwoContainersLayout from "../components/layout/twoContainersLayout";

import * as styles from "./services.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { SEOv2 } from "../components/seov2";

const Services = ({ data }) => (
  <Layout>
    <Section type="nearly-black" className={styles.firstSectionContainer}>
      <TwoContainersLayout>
        <div className={styles.left}>
          <span className={styles.heading}>Услуги</span>
        </div>
        <div className={styles.rightS1}>
          <StaticImage
            className={styles.heroImage}
            objectFit="contain"
            alt="services hero image"
            src="../assets/images/services.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.firstSectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[0].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s1.png"
            placeholder="blurred"
          />
        </div>
        <div className={styles.right}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[0].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[0].text.data.text}
          </ReactMarkdown>
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[1].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[1].text.data.text}
          </ReactMarkdown>
        </div>
        <div className={classNames(styles.right, styles.reverseOrderOnMobile)}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[1].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s2.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[2].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s3.png"
            placeholder="blurred"
          />
        </div>
        <div className={styles.right}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[2].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[2].text.data.text}
          </ReactMarkdown>
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[3].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[3].text.data.text}
          </ReactMarkdown>
        </div>
        <div className={classNames(styles.right, styles.reverseOrderOnMobile)}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[3].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s4.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[4].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s5.png"
            placeholder="blurred"
          />
        </div>
        <div className={styles.right}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[4].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[4].text.data.text}
          </ReactMarkdown>
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionDescriptionContainer}>
      <TwoContainersLayout>
        <div className={styles.leftBody}>
          <span className={styles.descriptionTitle}>
            {data.allStrapiService.nodes[5].title}
          </span>
          <ReactMarkdown className={styles.markdown}>
            {data.allStrapiService.nodes[5].text.data.text}
          </ReactMarkdown>
        </div>
        <div className={classNames(styles.right, styles.reverseOrderOnMobile)}>
          <span className={styles.descriptionTitleMobile}>
            {data.allStrapiService.nodes[5].title}
          </span>
          <StaticImage
            className={styles.imageMobile}
            objectFit="contain"
            alt=""
            src="../assets/images/s6.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <Title className={styles.mobileTitle}>Как да помогнем?</Title>
      <Button
        className={styles.button}
        title={"Свържете се с нас"}
        to="/contacts"
      />
    </Section>
    <Section type="lightest-green" className={styles.sectionContainer}>
      <Title className={styles.mobileTitle}>С какво разполагаме?</Title>
      <div className={styles.grid}>
        <p>{data.allStrapiServicesPage.edges[0].node.workshop_desc}</p>
        <p className={styles.rowSpan2}>
          {data.allStrapiServicesPage.edges[0].node.radio_lab_desc}
        </p>
        <p>{data.allStrapiServicesPage.edges[0].node.lab_desc}</p>
      </div>
    </Section>
    <Section type="nearly-black" className={styles.sectionContainer}>
      <Title
        className={classNames(styles.title, styles.mobileTitle)}
        inverted={true}
      >
        Нашият екип разполага с опит и експертиза в областта на:
      </Title>
      <TwoContainersLayout className={styles.servicesDesctiption}>
        <div className={classNames(styles.leftBody, styles.hqImage)}>
          <StaticImage
            objectFit="contain"
            alt=""
            src="../assets/images/our_team.png"
            placeholder="blurred"
          />
        </div>
        <div className={classNames(styles.right, styles.teamInformationText)}>
          <ReactMarkdown className={styles.markdown}>
            {data.strapiServicesPageTeamInformationTextnode["Team_Information"]}
          </ReactMarkdown>
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <Title className={styles.mobileTitle}>Свържете се с нас</Title>
      <Button
        className={styles.button}
        title={"Свържете се с нас"}
        to="/contacts"
      />
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiService(sort: { fields: createdAt }) {
      nodes {
        title
        text {
          data {
            text
          }
        }
      }
    }
    strapiServicesPageTeamInformationTextnode {
      Team_Information
    }
    allStrapiServicesPage {
      edges {
        node {
          radio_lab_desc
          workshop_desc
          lab_desc
        }
      }
    }
  }
`;

export default Services;

export const Head = () => <SEOv2 title="Услуги" previewImg="services" />;
